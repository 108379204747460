<template>
  <div class="ms_footer overflow-hidden">
    {{ $t('footer.titolo') }}
    <div class="cin">
      <div>
        IT014037B4SHXW5THN
      </div>
      <div>
        IT014037B4AKR6BGUH
      </div>
      <div>
        IT014037B4AJRBAZ6A
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Footer-',
}
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.ms_footer{
  font-family: 'Playball', cursive;
  background-color: green;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.cin{
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  justify-self: right 0;
  font-size: 5px;
  position: absolute;
  right: 10px;

}

</style>
